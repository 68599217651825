import { Component, Input, Output, ChangeDetectorRef } from '@angular/core';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { PagesService } from '../../services/pages.service';
import { AccountsService } from '../../services/accounts.service';
import { fromEvent, Subscription, BehaviorSubject } from 'rxjs';
import { debounceTime, ignoreElements, take, tap } from 'rxjs/operators';
import { AuthService } from '../../auth.service';

import { catchError, flatMap } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { Subject } from 'rxjs/Subject';
import { AreYouSureDialogComponent } from '../are-you-sure-dialog/are-you-sure-dialog.component';
import { AnalyticsService } from '../../services/analytics.service';
import { ToasterService } from '../../services/toaster.service';
import { RenderingService } from '../../services/rendering.service';

import {DomSanitizer} from '@angular/platform-browser';

import { MediaService } from '../../services/media.service';
import { SubscriptionDialogFacade } from '../../pages/profile/subscription/subscription-dialog.facade';

declare const ConvertApi;

@Component({
  selector: 'ngx-media-library-dialog',
  templateUrl: 'media-library-dialog.component.html',
  styleUrls: ['media-library-dialog.component.scss'],
})
export class MediaLibraryDialogComponent {
  list;
  files;
  urls;
  folders;
  nextPageToken;
  selectedView = 'files';
  selectedSubFolder;
  pdfLoading = false;
  folder;
  user;
  uploadData = {};
  orgInfo: any = {};
  admin = false;
  uploadType;
  uploadingDone = true;

  @Output() processMediaItems;
  @Input() variable;
  @Input() startUploadPresentation = false;
  @Input() selectedSearch;
  @Input() selectedTab;
  @Input() userActiveFolder;
  @Input() hideUnsplash = null;
  @Input() hidePexels = null;
  @Input() isEditorV4User = false
  @Input() multiSelect = false;
  @Input() defaultView = null;

  isProcessingMultiSelect = false;
  firstFilesLoad = new BehaviorSubject<boolean>(true);

  displayedFolders = [];
  showFolders = false;
  selectedFiles: Array<any> = [];
  selectedFolder;
  libraryState$: Subscription
  libraryStateObject;

  isTeam: boolean;
  isPro: boolean;
  isTrial: boolean;

  track(event, params = {}) {
    this.analyticsService.track(event, {
      ...params
    });
  }

  constructor(private dialogService: NbDialogService,
              public auth: AuthService,
              private accountsService: AccountsService,
              private cdr: ChangeDetectorRef,
              private subscriptionDialogFacade: SubscriptionDialogFacade,
              private analyticsService: AnalyticsService,
              private mediaService: MediaService,
              protected ref: NbDialogRef<MediaLibraryDialogComponent>,
              protected sanitizer: DomSanitizer,
              private renderingService: RenderingService,
              private toasterService: ToasterService) { }

  ngOnInit() {
    this.track('view assets library');
    this.auth.isAdmin$().subscribe(admin => this.admin = admin);
    this.auth.getUser$().subscribe(user => this.user = user);
    this.accountsService.info().subscribe(orgInfo => this.orgInfo = orgInfo);
    if(this.startUploadPresentation) this.uploadPdf()
    this.skipLibrary();
    this.openPexel();
    this.mediaService.libraryCurrentState$.pipe(take(1)).subscribe(state => {
      if (!state) return
      this.selectedView = state['view'];
      this.libraryStateObject = state;
    })
    
    this.mediaService.closeMediaLibrary$.pipe(take(1)).subscribe(() => {
      this.dismiss();
    });

    if (this.defaultView) this.selectedView = this.defaultView;

    this.isTeam = this.accountsService.isTeam;
    this.isPro = this.accountsService.isPro;
    this.isTrial = !this.accountsService.isPaid;
  }

  onScrollDown() {
    this.mediaService.updateScroll('fetchMedia');
  }

  skipLibrary() {
    if(!this.variable) return
    setTimeout(() => {
      if(this.variable.skipLibrary) this.uploadMedia();
    }, 100);
  }
  openPexel() {
    if(!this.variable) return
    if(this.variable.openPexels) this.selectedView = 'pexels';
    if(this.selectedTab) this.selectedView = this.selectedTab
  }

  get allowedContent() {
    if(!this.variable) return null;
    if(this.variable && this.variable.type === 'video' || this.hideUnsplash) {
      return 'video';
    } else if(this.variable && this.variable.type === 'image' || this.hidePexels) {
      return 'image';
    } else {
      return 'image-video';
    }
  }

  get getAccept() {
    if(!this.allowedContent || this.allowedContent === 'image-video') {
      return 'image/*,video/mp4';
    } else if (this.allowedContent === 'image') return 'image/*';
    else if (this.allowedContent === 'video') return 'video/mp4';
    else return this.allowedContent + '/*';
  }


  get fonts() {
    return this.admin ? ',font/*,.woff,.woff2,.ttf,.otf' : '';
  }

  uploadMedia() {
    document.getElementById('filePicker').click();
  }

  uploadPdf() {
    this.track('click upload presentation');
    if(this.pdfLoading) return;
    document.getElementById('pdfPicker').click();
  }

  filesChanged(event) {
    const fileElement: any = document.getElementById('filePicker');
    this.files = fileElement.files;
    if(!this.files.length) return;
    this.uploadType = 'files';
    this.selectedView = 'uploader';
    this.uploadingDone = false;
  }

  finishedUploading() {
    if(this.uploadType === 'files') {
      this.track('success upload media');
      const fileElement: any = document.getElementById('filePicker');
      if(fileElement) fileElement.value = null;
    } else {
      this.track('success upload presentation');
      if(this.startUploadPresentation) {
        this.dialogService.open(AreYouSureDialogComponent, {
          context: {
            body: '<h2 style="color: #7106EE;font-weight: 700;font-size: 28px;">TA-DA! ✨</h2>The magic is done, start creating your Storydoc<br />',
            buttons: [{
              title: 'Let\'s go!',
              value: true,
              status: 'primary'
            }]
          }
        }).onClose.subscribe(() => this.dismiss());
      }
    }
    this.uploadingDone = true
  }

  // Extension MIME Type
  //   .doc      application/msword
  //   .dot      application/msword
  //
  //   .docx     application/vnd.openxmlformats-officedocument.wordprocessingml.document
  //   .dotx     application/vnd.openxmlformats-officedocument.wordprocessingml.template
  //   .docm     application/vnd.ms-word.document.macroEnabled.12
  //   .dotm     application/vnd.ms-word.template.macroEnabled.12
  //
  //   .xls      application/vnd.ms-excel
  //   .xlt      application/vnd.ms-excel
  //   .xla      application/vnd.ms-excel
  //
  //   .xlsx     application/vnd.openxmlformats-officedocument.spreadsheetml.sheet
  //   .xltx     application/vnd.openxmlformats-officedocument.spreadsheetml.template
  //   .xlsm     application/vnd.ms-excel.sheet.macroEnabled.12
  //   .xltm     application/vnd.ms-excel.template.macroEnabled.12
  //   .xlam     application/vnd.ms-excel.addin.macroEnabled.12
  //   .xlsb     application/vnd.ms-excel.sheet.binary.macroEnabled.12
  //
  //   .ppt      application/vnd.ms-powerpoint
  //   .pot      application/vnd.ms-powerpoint
  //   .pps      application/vnd.ms-powerpoint
  //   .ppa      application/vnd.ms-powerpoint
  //
  //   .pptx     application/vnd.openxmlformats-officedocument.presentationml.presentation
  //   .potx     application/vnd.openxmlformats-officedocument.presentationml.template
  //   .ppsx     application/vnd.openxmlformats-officedocument.presentationml.slideshow
  //   .ppam     application/vnd.ms-powerpoint.addin.macroEnabled.12
  //   .pptm     application/vnd.ms-powerpoint.presentation.macroEnabled.12
  //   .potm     application/vnd.ms-powerpoint.template.macroEnabled.12
  //   .ppsm     application/vnd.ms-powerpoint.slideshow.macroEnabled.12
  //
  //   .mdb      application/vnd.ms-access

  get allowedPresentationFiles() {
    return {
      "application/pdf": "pdf",
      "application/msword": "doc",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document": "docx",
      "application/vnd.ms-powerpoint": "ppt",
      "application/vnd.openxmlformats-officedocument.presentationml.presentation": "pptx"
    };
  }

  sendPdfToSpreadsheets(pdfFile) {
    this.uploadData["professional-email"] = this.user.sub;
    this.uploadData["company-name"] = this.orgInfo.title;
    this.uploadData["org-id"] = this.orgInfo._id;
    this.uploadData["send-email"] = "false";

    // SUBMIT DATA TO SPREADSHEET + GOOGLE DRIVE
    const formUrl = "https://script.google.com/macros/s/AKfycbx4NATB3ax-gyTtkH51_Sg6lmajkYGh9ZldwipZqFzs7gYTswXBAiOOq3mNQNs_J5c/exec";
    const driveUrl = "https://script.google.com/macros/s/AKfycbyqspLLOXLIEPD6TbmeCpOgPC4CF9sDT0oyXvLTd4Mi1dVX4qkaEaVXyJpDkA7mji3P/exec";

    const file = pdfFile;
    const fr = new FileReader();
    fr.readAsArrayBuffer(file);

    fr.onload = (f: any) => {

      const qs = new URLSearchParams({filename: file.name, mimeType: file.type});
      this.renderingService.makePostRequest(`${driveUrl}?${qs}`, JSON.stringify([...new Int8Array(<ArrayBuffer>f.target.result)]))
      .pipe(flatMap((e: any) => {
        this.uploadData["deck-link"] = e.fileUrl

        const formData = new FormData();
        Object.keys(this.uploadData).forEach(key => formData.append(key, this.uploadData[key]));

        return this.renderingService.makePostRequest(formUrl, formData);

      }))
      .pipe(
        catchError((err: any) : Observable<any> => {
          console.log("Error:", err)
          this.track('failed upload presentation to spreadsheet');
          return throwError(err)
        })
      )
      .subscribe(response => console.log('Success!', response))

    }
  }
  pdfChanged() {
    const pdfFile:any = document.getElementById('pdfPicker');
    if(!pdfFile.files[0]) return;

    if(pdfFile.files[0].type !== 'application/pdf') {
    // if(!Object.keys(this.allowedPresentationFiles).includes(pdfFile.files[0].type)) {
      this.toasterService.showToast('danger', 'Please select a pdf file type');
      return;
    }
    this.sendPdfToSpreadsheets(pdfFile.files[0])
    this.folder = pdfFile.files[0].name;
    this.uploadingDone = false;
    this.pdfLoading = true;
    this.uploadType = 'urls';
    this.files = null;
    this.urls = null;
    this.selectedView = 'uploader';
    this.mediaService.convertApiToken()
    .pipe(
      catchError((err: any) : Observable<any> => {
        this.toasterService.showToast('danger', 'Error uploading your presentation', 'Please try again');
        this.uploadingDone = true;
        this.pdfLoading = false;
        pdfFile.value = null;
        return throwError(err)
      })
    )
    .subscribe((res: any) => {
      const file = pdfFile.files[0];
      const type = this.allowedPresentationFiles[file.type];
        // directly convert to images
        const formData = new FormData();
        formData.append('File', file);
        formData.append('StoreFile', 'true');
        formData.append('ImageOutputFormat', 'png')
        this.renderingService.makePostRequest(`https://v2.convertapi.com/convert/pdf/to/extract-images?Token=${res.token}`, formData)
        .pipe(catchError((err: any) : Observable<any> => {
          console.log("Error:", err);
          this.toasterService.showToast('danger', 'Error uploading your presentation', 'Please try again');
          this.failedUploadPresentation(err.message);
          return throwError(err)
        }))
        .subscribe(result => {
          pdfFile.value = null;
          this.pdfLoading = false;
          this.urls = result.Files.filter(f => !['tif', 'tiff', 'tiff-fax'].includes(f.FileExt)).map(f => f.Url);
          if(!this.urls || !this.urls.length) {
            this.toasterService.showToast('danger', 'No images found in your presentation', 'Please try a different presentation');
            this.failedUploadPresentation('No images found')
          }
        })
    });
  }

  failedUploadPresentation(message) {
    this.uploadingDone = true;
    this.pdfLoading = false;
    const pdfFile:any = document.getElementById('pdfPicker');
    pdfFile.value = null;
    this.selectedView = 'files'
    this.track('failed to extract images from presentation', {
      'failure message': message
    });
  }

  get canClose() {
    return this.uploadingDone;
  }

  dismiss() {
    this.ref.close();
  }
  getUserFolders(event) {
    this.displayedFolders = event;
  }

  toggleFoldersVisibility() {
    this.showFolders = !this.showFolders;
    if(this.selectedView !== 'files') this.showFolders = false;
  }
  setActiveFolder(folder) {
    if(this.selectedView !== 'files') this.selectedView = 'files';
    this.selectedFolder = folder;
  }
  setMyFilesActive() {
    this.selectedView = 'files';
    this.selectedFolder = null;
  }

  approveSelectedFiles() {
    if (this.processMediaItems) {
      if (this.isProcessingMultiSelect) return;

      this.isProcessingMultiSelect = true;
      this.processMediaItems(this.selectedFiles, () => {
        this.ref.close();
      });
    }
    else this.ref.close(this.selectedFiles);
  }


  navigationHandler(view: string) {
    if(view === 'openai' && this.isTrial) return this.upgradeToStarterDialog();
    if (this.canClose) this.selectedView = view
    else this.selectedView = null;
    this.mediaService.updateMediaLibraryState(null);
    this.libraryStateObject = null;
    this.cdr.detectChanges();
  }

  upgradeToStarterDialog() {
    this.subscriptionDialogFacade.openPurchaseDialog({ source: 'Media library AI images', plan: 'Starter', title: "Unlock this feature <br> by going <strong class='--starter'>Starter</strong>" })
  }
}
