import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { Observable, Subscription, throwError } from 'rxjs';
import { catchError, map, pairwise, startWith, take } from 'rxjs/operators';
import { AuthService } from '../../auth.service';

// Services
import { AccountsService } from '../../services/accounts.service';
import { AnalyticsService } from '../../services/analytics.service';
import { ToasterService } from '../../services/toaster.service';
import { EditorFacade } from '../../pages/editor/editor.facade';
import { RenderingService } from '../../services/rendering.service';
import { SenderDataService } from '../../pages/account/account.service';

interface Customization {
  logo: string|null;
  backgroundImage: string|null;
  overlayOpacity: string;
  overlayColor: string;
  isDarkTheme: boolean;
} 

@Component({
  selector: 'ngx-account-customization-dialog',
  templateUrl: 'account-customization-dialog.component.html',
  styleUrls: [ 'account-customization-dialog.component.scss' ],
})
export class AccountCustomizationDialogComponent implements OnInit, OnDestroy {

  constructor(protected ref: NbDialogRef<AccountCustomizationDialogComponent>,
    private accountsService: AccountsService,
    private toasterService: ToasterService,
    private editorFacade: EditorFacade,
    private senderDataService: SenderDataService,
    private auth: AuthService,) { }

  orgInfo;
  customization: Customization = {
    logo: null,
    backgroundImage: null,
    overlayOpacity: '80',
    overlayColor: '#FFFFFF',
    isDarkTheme: false,
  };
  placeholder: string = '/assets/images/image-placeholder.png'
  favicon: string = '';
  form: FormGroup
  formSubscription: Subscription
  changedValues = {};
  canUpdate: boolean = false;
  loading: boolean = false;
  isAdminCore$ = this.auth.getAdminCore$();
  accountCustomization$ = this.accountsService.accountCustomization$;
  isStarterOrTrial: boolean = false;

  adminVariables  = [
    { "tab": "Content", "group": "Background:", "type": "slider", "name": "overlayOpacity", "title": "Overlay opacity", "suffix": "%", "default": '', "tip": "Overlay opacity (%)", "min": 0, "max": 90, "step": 10, "validation": { "required": false } },
    { "tab": "Content", "group": "Background:", "type": "toggle", "name": "isDarkTheme", "title": "Dark Background Mode", "default": false, "tip": "Dark Background Mode", "validation": { "required": false } }
  ];

  defaultFavicon = 'https://www.storydoc.com/assets/images/branding/favicon.png';



  ngOnInit(): void {
    this.isStarterOrTrial = this.accountsService.isStarterOrTrial;
    this.accountsService.info().subscribe(orgInfo => {
      this.orgInfo = orgInfo;
      this.initForm();
      this.formSubscription = this.form.valueChanges.pipe(
        startWith(this.form.value),
        pairwise(),
        map(([prev, curr]) => Object.keys(curr).find(key => prev[key] !== curr[key]))
      )
      .subscribe(key => {
        this.changedValues[ key ] = this.form.get(key).value
        this.canUpdate = true;
      })
    })
  }

  initForm(): void {
    const { customization, favicon } = this.orgInfo;
    if (customization) {
      Object.keys(customization).forEach(key => {
        if (!customization[ key ]) return
        else this.customization[ key ] = customization[ key ];
      })
    }
    if (favicon) this.favicon = favicon;

    this.form = new FormGroup({
      'logo': new FormControl(this.customization.logo),
      'favicon': new FormControl(this.favicon),
      'backgroundImage': new FormControl(this.customization.backgroundImage),
      'overlayOpacity': new FormControl(this.customization.overlayOpacity),
      'overlayColor': new FormControl(this.customization.overlayColor),
      'isDarkTheme': new FormControl(this.customization.isDarkTheme),
    })
  }


  chooseImage(type: string) {
    const variable = { "type": "image", "validation": {} };
    this.editorFacade.chooseImage(variable).subscribe(res => {
      if (!res) return this.chooseImage(type);
      if (res[ 'media' ]) return;
      this.form.get(type).setValue(res.url);
    });
  }

  removeImage(type: string) {
    this.form.get(type).setValue(null);
  }

  setFavicon(event) {
    this.form.get('favicon').setValue(event);
  }

  changeOverlayColor() {
    const overlayColor = this.form.get('overlayColor').value;
    this.editorFacade.chooseColor({ color: overlayColor, dsDefault: overlayColor, isV2: true, })
    .subscribe(color => {
      if(color) this.form.get('overlayColor').setValue(color)
    })
  }

  update() {
    if (!this.canUpdate) return
    this.loading = true;
    const customization = {...this.customization, ...(this.orgInfo.customization || {})}; // get default and current

    Object.keys(this.customization).forEach(key => { // update changed
      if (!this.changedValues.hasOwnProperty(key)) return;
      customization[ key ] = this.changedValues[ key ];
      delete this.changedValues[ key ];
    })

    this.accountsService.customization({customization, ...this.changedValues})
    .pipe(
      catchError((err: any): Observable<any> => {
        this.loading = false;
        this.toasterService.showToast('danger', 'Failed to update account customization', 'Please try again in a few minute');
        return throwError(err);
      })
    )
    .subscribe(orgInfo => {
      this.toasterService.showToast('success', 'Account customization updated successfully');
      this.accountsService.updateAccountCustomization(orgInfo.customization)
      const org = { title: orgInfo.title, website: orgInfo.website || '', logo: orgInfo.customization.logo || '' };
      this.senderDataService.updatePersonalInfoData({ ...this.senderDataService.personalInfoData$.value, org })
      this.accountsService.updateOrgInfo(orgInfo);
      this.loading = false;
      this.close(orgInfo);
    });
  }

  close(info = null) {
    this.ref.close(info);
  }

  ngOnDestroy(): void {
    if (this.formSubscription) this.formSubscription.unsubscribe();
  }
}
