import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, from, of, forkJoin, throwError } from 'rxjs';
import { mergeMap, flatMap, catchError, switchMap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { HandlebarsService } from './handlebars.service';
import { ComponentsService } from './components.service';
import { AccountsService } from './accounts.service';
import { PagesService } from './pages.service';
import { AuthService } from '../auth.service';
import { AnalyticsService } from './analytics.service';
import { SenderDataService } from '../pages/account/account.service';


import uuid from 'uuid';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class RenderingService {

  constructor(private http: HttpClient,
              private accountsService: AccountsService,
              private componentsService: ComponentsService,
              private pagesService: PagesService,
              private authService: AuthService,
              private analyticsService: AnalyticsService,
              private senderDataService: SenderDataService,
              private handlebarsService: HandlebarsService) {}


  get orgInfo() {
    return this.accountsService.orgInfo;
  }

  get orgId() {
    return this.orgInfo._id;
  }

  searchBrand(search: string) {
    return this.http.get(`https://api.brandfetch.io/v2/search/${search}`);
  }

  brandfetch(endpoint, domain, disableRateLimit = false) {
    return this.http.post(environment.backend + '/brandfetch/'+endpoint, {domain, disableRateLimit});
  }

  brandfetchV2(domain, disableRateLimit = false, isLibrary = false) {
    return this.http.post(environment.backend + '/brandfetch', {domain, disableRateLimit, isLibrary});
  }

  brandfetchLogoFinder(domain: string) {
    return this.http.post(environment.backend + '/brandfetch/logo-finder', {domain});
  }

  showImageLimit(): Observable<any> {
    let url = environment.backend + '/brandfetch/showImageLimit';
    return this.http.get(url);
  }

  websiteWizardPolling(id: string): Observable<any> {
    return this.http.get(environment.backend + `/wizard/post-signup?id=${id}`);
  }

  wizardGeneratePrompt(data: Record<string, any>): Observable<any> {
    const endpoint = data.isOutlinesMode ? '/wizard/prompt-outlines' : '/wizard/prompt-with-scraper';
    return this.http.post(`${environment.backend}${endpoint}`, { data });
  }

  requestUploadFileToken(filename, contentType, typeStory = false) {
    // return this.http.get(environment.backend + '/pages/getSignedPostPolicy?contentType='+contentType);
    return this.http.post(environment.backend + '/pages/uploadFileToken', {
      filename,
      contentType,
      typeStory
    });
  }

  updateOrgId(orgId) {
    this.accountsService.setOrgId(orgId);
    this.authService.setOrgId(orgId);
  }

  // return an observable of a story
  createStory(title, template, theme = null, status = 'Draft', orgId = null, isEditorV4 = false) {
    const currentOrgId = template.orgId;
    let story: any = {
      settings: template.settings,
      status,
      pending: true
    };
    if(isEditorV4) {
      story.slides = template.slides;
      story.previewUrl = template.previewUrl;
    }
    if(template.folderId) story.folderId = template.folderId;
    if(orgId) {
      story.orgId = orgId;
      this.updateOrgId(orgId);
    }
    story.settings.title = title;
    story.settings.canonical = uuid.v4(); // give a unique page canonical url
    if(theme) story.settings.designSystemV2 = theme.designSystem;
    story.settings.favicon = "";
    if (this.orgInfo.analyticsDisable) story.settings.analyticsDisable = this.orgInfo.analyticsDisable;
    const isV5Layout = this.isFlatContentVersion(template.layoutVersion) || this.isNormalizedNestedStructure(template.slides);


    const story$ = !theme
      ? this.pagesService.create(story)
      : this.uploadCss(template, 'new').pipe(flatMap(cssUrl => {
        story.settings.cssUrl = cssUrl;
        return this.pagesService.create(story)
      }));

    return story$.pipe(
      switchMap(story => {
        story.pending = false;
        story.layoutVersion = isV5Layout ? '5.0.0' : '4.0.0';
        return isV5Layout ? this.uploadFlatStoryMetadata$(story) : from([ story ])
      }),
      flatMap(res => {return this.pagesService.update(res._id, res)}),
      flatMap((story) => {
        if (orgId && currentOrgId) this.updateOrgId(currentOrgId);
        return from([story])
      }),
      catchError((err: any): Observable<any> => {
        if (orgId && currentOrgId) this.updateOrgId(currentOrgId);
        return throwError(err)
      })
    )
  }

  isFlatContentVersion(layoutVersion: string) {
    let isStoryV5 = false;
    if(!layoutVersion) return isStoryV5;
    try {
      if (layoutVersion !== 'latest') {
        const parsed = Number(layoutVersion[0]);
        if (!isNaN(parsed) && parsed >= 5) isStoryV5 = true;
      }
    } catch (e) {
      console.error(e);
    }
    return isStoryV5;
  };

  uploadFlatStoryMetadata$(story) {
    const hasSlides = this.isNormalizedNestedStructure(story.slides);
    const filename = `${story.orgId}/${story._id}/metadata/${new Date().getTime()}`;
    const slides$ = hasSlides
      ? from([ story.slides ])
      : this.getContentPointerSlides$(story.settings.contentPointer);

    return slides$.pipe(mergeMap((slides: any) => {
      const content = { ...story, slides };
      return this.requestUploadFileToken(filename, 'application/json')
      .pipe(mergeMap((result: any) => {
        const url = environment.assets + '/' + result.url.fields.key;
        const blob = new Blob([JSON.stringify(content)], {type : 'application/json'});
        return this.uploadFilePost(result.url, blob)
        .pipe(mergeMap(() => {
          const settings = { ...content.settings, contentPointer: url };
          content.slides = [];
          return from([{ ...content, settings }]);
        }));
      }));
    }))
  }

  getContentPointerSlides$(contentPointer: string) {
    return this.http.get(contentPointer).pipe(switchMap((story: any) => of(story.slides)));
  }

  isNormalizedNestedStructure(slides: unknown) {
    return Array.isArray(slides) && slides.length > 0 && slides[0].id && slides[0].children;
  }


  publishStory(story) {
    if(story.settings.designSystemV2) { // if ds v2 render specifically for prod
      return this.uploadCss(story, 'production')
      .pipe(mergeMap(cssUrl => {
        return this.saveStory(story, false)
      }))
    } else {
      return this.saveStory(story, false)
    }
  }

  previewPageComponent$(page, pc) {
    return this.componentsService.get(pc.componentId)
    .pipe(flatMap(component => {
      return this.previewPageComponent(component, pc, page)
    }))
  }

  previewPageComponent(component, pc, page) {
    const pageComponent: any = this.renderPageComponent(component, pc, page.settings);
    const blob = new Blob([pageComponent.renderedFull], {type : 'application/html'});
    return this.uploadComponentPreview(page, blob)
    .pipe(mergeMap((url) => {
      pageComponent.url = url
      return from([pageComponent]);
    }));
  }

  uploadComponentPreview(story, blob) {
    // orgId/pageId/page-component/randomuuid?1629387016157&primary=true
    const filename = `preview/${story.orgId}/${story._id}/page-component/${uuid.v4()}`;
    return this.requestUploadFileToken(filename, 'text/html', true)
    .pipe(mergeMap((result: any) => {
      const url = environment.stories + '/' + result.url.fields.key;
      return this.uploadFilePost(result.url, blob)
      .pipe(mergeMap((result) => from([url])));
    }));
  }

  uploadStoryPreview(story) {
    return this.completePageComponents$(story)
    .pipe(flatMap(pageComponents => {
      story.pageComponents = pageComponents;
      let filename = 'preview/' + this.createStoryFileKey(story, true);
      return this.uploadStory(story, filename, true);
    }))
  }

  uploadStoryMetadata(story) {
    story.pageComponents.map(pc => {
      delete pc.component;
      delete pc.renderedFull;
    })
    const filename = `preview/${story.orgId}/${story._id}/metadata/${new Date().getTime()}`;
    return this.requestUploadFileToken(filename, 'application/json')
    .pipe(mergeMap((result: any) => {
      const url = environment.assets + '/' + result.url.fields.key;
      const blob = new Blob([JSON.stringify(story)], {type : 'application/json'});
      return this.uploadFilePost(result.url, blob)
      .pipe(mergeMap((result) => from([url])));
    }));
  }

  productionCssUrl(story) {
    return this.orgId + '/stylesheet/production/' + story._id + '.css'; // v4 is a random uuid
  }

  stagingCssUrl(story, isnew = false) {
    if(isnew) return this.orgId + '/stylesheet/staging/' + uuid.v4() + '/layout-preview.css'; // v4 is a random uuid
    return story.settings.cssUrl.replace(environment.assets + '/', '');
  }

  tmpCssUrl(story) {
    return this.orgId + '/stylesheet/staging/' + uuid.v4() + '/layout-temp.css'; // v4 is a random uuid
  }

  uploadCss(story, mode = 'tmp', isEditorv4 = false) {
    // patch for non v2
    if(!story.settings.designSystemV2) return from([story.settings.cssUrl]);

    let cssOutdated = false;
    // check if css url is not pointing to the current orgId
    if(mode === 'staging' && story.settings.cssUrl.indexOf(this.orgId) === -1) {
      mode = 'new'
      cssOutdated = true;
    };

    let filename = this.tmpCssUrl(story);
    if(mode === 'production') filename = this.productionCssUrl(story);
    else if(mode === 'staging') filename = this.stagingCssUrl(story);
    else if (mode === 'new') filename = this.stagingCssUrl(story, true);
    const customFonts = this.getUsedCustomFonts(story.settings.designSystemV2);
    const cssContent = this.handlebarsService.renderCSS({...story.settings.designSystemV2, customFonts });
    return this.requestUploadFileToken(filename, 'text/css')
    .pipe(mergeMap((result: any) => {
      const url = environment.assets + '/' + result.url.fields.key;
      const blob = new Blob([cssContent], {type : 'text/css'});
      return this.uploadFilePost(result.url, blob)
      .pipe(mergeMap((result) => {
        if(cssOutdated) {
          story.settings.cssUrl = url;
          if(isEditorv4) return from([url])
          return forkJoin(story.pageComponents.map(pc => this.previewPageComponent$(story, pc)))
          .pipe(flatMap(pageComponents => {
            story.pageComponents = pageComponents;
            return from([url])
          }));
        } else {
          return from([url])
        }
      }));
    }));
  }

  getUsedCustomFonts(designSystem: Record<string, string>) {
    const customFonts = this.orgInfo.fonts && this.orgInfo.fonts.custom ? this.orgInfo.fonts.custom : [];
    if(!customFonts.length) return [];
    const { titleFont, subtitleFont, paragraphFont } = designSystem;
    const usedFonts = [ titleFont, subtitleFont, paragraphFont ];
    return customFonts.filter((font: { title: string, url: string, weight: number}) => usedFonts.includes(font.title));
  }

  uploadStory(story, filename, preview = false, version = null) {
    // TODO: validate following:
    // lastUpdateUserEmail
    // lockStory
    // const filename = previewUrl + this.getStoryPath(story);
    return this.requestUploadFileToken(filename, 'text/html', true)
    .pipe(mergeMap((result: any) => {
      const url = environment.stories + '/' + result.url.fields.key;
      const doc = this.renderStory(story, url, {production: !preview, version});
      const blob = new Blob([doc], {type : 'text/html'});
      return this.uploadFilePost(result.url, blob)
      .pipe(mergeMap((result) => from([url])));
    }));
  }

  uploadStoryVersion(story, version) {
    let filename = this.getStoryFileKey(story, version._id);
    return this.requestUploadFileToken(filename, 'text/html', true)
    .pipe(mergeMap((result: any) => {
      const url = environment.stories + '/' + result.url.fields.key;
      const docTemplate = this.renderStory(story, url, {production: true, version});
      const doc = this.handlebarsService.render(docTemplate, version.data);
      const blob = new Blob([doc], {type : 'text/html'});
      return this.uploadFilePost(result.url, blob)
      .pipe(mergeMap((result) => from([url])));
    }));
  }

  completePageComponents$(page) {
    const pcs$ = page.pageComponents.map(pc => {
      if(pc.renderedHTML) {
        // is rendered
        return of(pc);
      } else {
        // is a slim version and not rendered yet
        return this.previewPageComponent$(page, pc);
      }
    })
    return forkJoin(pcs$);
  }

  saveStory(storyOriginal, preview = true, metadata = true) {
    const story = JSON.parse(JSON.stringify(storyOriginal)); // clone deep
    return this.completePageComponents$(story)
    .pipe(flatMap(pageComponents => {
      story.pageComponents = pageComponents;
      let filename = this.createStoryFileKey(story, preview);
      if(preview) filename =  'preview/' + filename;
      story.previewUrl = environment.stories + '/' + filename; //This line added later so stories who didn't been saved since 25/5/22 may have outdated previewUrl files
      return forkJoin([
        metadata ? this.uploadStoryMetadata(story) : from([null]),
        this.uploadStory(story, filename, preview)
      ]);
    }))
  }

  saveVersion(storyOriginal, version) {
    const story = JSON.parse(JSON.stringify(storyOriginal)); // clone deep
    return this.completePageComponents$(story)
    .pipe(flatMap(pageComponents => {
      story.pageComponents = pageComponents;
      let filename = this.createVersionFileKey(story, version._id);
      return this.uploadStory(story, filename, false, version);
    }))
  }

  uploadFilePost(data, file): Observable<any> {
    const formData = new FormData();
    for(let key in data.fields) {
      formData.append(key, data.fields[key]);
    }
    formData.append('file', file);
    return this.http.post(data.url, formData)
    .pipe(catchError((err: any) : Observable<any> => {
      this.analyticsService.track('failed upload file to google storage', {
        'failure message': err.message
      });
      return throwError(err)
    }));
    // return this.http.post(data.url, formData, {reportProgress: true, observe: "events"})
  }

  makePostRequest(url, formData) {
    return this.http.post(url, formData);
  }

  marketingContentOrgId = '36a3a2a42b5b702f';
  get isMarketingContentOrg() {
    return this.orgId === this.marketingContentOrgId;
  }


  getStoryPath(story, versionId = null) {
    const pre = (this.isMarketingContentOrg) ? '' : this.orgId + '/';
    const base = pre + story.settings.canonical;
    return versionId ? base + '/' + versionId : base;
  }

  createStoryFileKey(story, preview = false) {
    // preview/orgId/storyId/timestamp
    if(preview) {
      return `${this.orgId}/${story._id}/${new Date().getTime()}`;
    } else {
      return this.getStoryFileKey(story);
    }
  }

  createVersionFileKey(story, versionId) {
    return this.getStoryFileKey(story, versionId);
  }


  getStoryFileKey(story, versionId = null) { // redundant
    return this.getStoryPath(story, versionId);
    // return versionId ? story.settings.canonical + '/' + versionId : story.settings.canonical;
  }

  generatePageComponent$(pc) {
    return this.componentsService.get(pc.componentId)
    .pipe(mergeMap(component => {
      const updatedpc = this.generatePageComponent(component, pc)
      return from([updatedpc]);
    }));
  }

  generatePageComponent(component, pc) {
    let values = pc.values;
    const componentClass = (pc.componentType === 'media') ? 'media-component' : 'component';
    const componentAnchor = pc.design && pc.design[ 'anchorId' ] ? `id="${pc.design[ 'anchorId' ]}"` : '';
    if(!values) values = {};
    values['id'] = uuid.v4(Date.now());

    return {
      values,
      componentId: component._id,
      componentType: component.componentType,
      previewImage: component.previewImage,
      url: pc.url,
      lastUpdated: pc.lastUpdated,
      design: pc.design,
      blockedComponent: pc.blockedComponent,
      renderedDependenciesHTML: this.handlebarsService.render(component.dependenciesHTML, values),
      renderedHTML: `<div class="${values['id']} ${componentClass}" ${componentAnchor}>\n\n${this.handlebarsService.render(component.html, values)}\n\n</div>`,
      renderedCss: this.handlebarsService.render(component.css, values) + this.getDesignCss(pc.design, values['id']),
      renderedJavascript: this.handlebarsService.render(component.javascript, values),
      // renderedFull: this.getRenderedComponent(component, values),
    }
  }

  updateToCloudinary(url) {
    if(!url) return url;
    return url
    .replace('https://assets-staging.storydoc.com', 'https://storydoc.mo.cloudinary.net/stg')
    .replace('https://assets.storydoc.com', 'https://storydoc.mo.cloudinary.net/prd')
  }


  getDesignCss(design, uuidComponent) {
    if(!design) return "";
    let style = "";
    if(design.minHeight) style += `
      div[class^="${uuidComponent}"] {
        min-height: ${design.minHeight};
        display: table;
        width: 100%;
      }
      div.component[class^="${uuidComponent}"] > * {
        display: table-cell;
        vertical-align: middle;
      }
    `;
    if(design.useImage) {
      style += `
        .component > * {
          position: relative;
          z-index: 2;
        }
        div[class^="${uuidComponent}"] {
          background-image: url("${this.updateToCloudinary(design.backgroundImage)}?tx=f_auto");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          position: relative;
        }
        div[class^="${uuidComponent}"]:after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: block;
          opacity: ${(design.overlayOpacity || 80) / 100};
          ${design.backgroundColor ? 'background-color: '+design.backgroundColor+' !important;' : ''}
        }
      `
    } else {
      style += `
        div[class^="${uuidComponent}"] {
          background: ${design.backgroundColor} !important;
        }
      `
    }

    if (design.desktopMargin && !Number.isNaN(Number(design.desktopMargin))) {
      style += `
      div[class^="${uuidComponent}"] {
        margin-top: ${Number(design.desktopMargin)}px;
      }
      `
    }
    if (design.desktopMarginBottom && !Number.isNaN(Number(design.desktopMarginBottom))) {
      style += `
      div[class^="${uuidComponent}"] {
        margin-bottom: ${Number(design.desktopMarginBottom)}px;
      }
      `
    }
    if (design.mobileMargin && !Number.isNaN(Number(design.mobileMargin))) {
      style += `
      @media screen and (max-width: 768px) {
        div[class^="${uuidComponent}"] {
          margin-top: ${Number(design.mobileMargin)}px;
        }
      }
      `
    }
    if (design.mobileMarginBottom && !Number.isNaN(Number(design.mobileMarginBottom))) {
      style += `
      @media screen and (max-width: 768px) {
        div[class^="${uuidComponent}"] {
          margin-bottom: ${Number(design.mobileMarginBottom)}px;
        }
      }`
    }

    return style;
  }

  getStoryMetadata$(story) {
    if(story.contentPointer) {
      return this.http.get(story.contentPointer)
    } else {
      return from([story]);
    }
  }

  getFileContents(url, params = {}) {
    if(url && url.indexOf('stories.storydoc.com') > -1) url = url.replace('stories.storydoc.com', 'www.storydoc.com')
    return this.http.get(url, params);
  }

  renderPageComponent(component, pc, pageSettings, system: any = {production: false}) {
    const pageComponent: any = this.generatePageComponent(component, pc);
    const data: any = this.combinePageComponents(pageSettings, [pageComponent]);
    system.fullPage = false;
    system.baseURL = environment.stories;
    system.orgId = this.orgId; // support orgId in template
    system.timestamp = moment().format('mmssDDMMYYYY');
    pageComponent.renderedFull = this.handlebarsService.renderHTML({
      url: environment.stories + '/preview/' + this.getStoryPath({settings: pageSettings}, this.orgId),
      system: system,
      settings: pageSettings,
      head: data.head,
      body: data.body,
      dependencies: data.dependencies + data.javascript
    });
    return pageComponent;
  }

  renderStory(story, url, system: any = {}) {
    const pageSettings = JSON.parse(JSON.stringify(story.settings));
    if(system.production && pageSettings.designSystemV2) pageSettings.cssUrl =  environment.assets + '/' + this.productionCssUrl(story);
    const data: any = this.combinePageComponents(pageSettings, story.pageComponents, { ignoreInvisible: true });
    system.timestamp = moment().format('mmssDDMMYYYY');
    system.fullPage = true;
    system.baseURL = environment.stories;
    if(!this.orgInfo) throw new Error('missing orgInfo');
    system.orgInfo = this.orgInfo;
    if(this.orgInfo.analyticsProperty) system.analyticsProperty = this.orgInfo.analyticsProperty;
    system.orgTitle = this.orgInfo.title;
    system.orgId = this.orgId;
    system.storyId = story._id;
    system.isAppSumo = this.accountsService.isAppSumo;
    system.isTrialOrStarter = !this.accountsService.isPaid || this.accountsService.isStarter;
    system.placeholders = {
      versionTitle: "{{system.version.data.title}}",
      ogImage: "{{system.version.data.ogImage}}",
      versionId: "{{system.version._id}}",
      userId: "{{system.version.userId}}",
    }
    system.isGatedContent = '{{system.version.data.gatedContent.gatedContent}}';
    system.gatedContentData = JSON.stringify([ {
      title: '{{system.version.data.gatedContent.title}}',
      logo: '{{system.version.data.gatedContent.logo}}',
      logoSRC: '{{system.version.data.gatedContent.logoSRC}}',
      name: '{{system.version.data.gatedContent.name}}',
      nameRequired: '{{system.version.data.gatedContent.nameRequired}}',
      email: '{{system.version.data.gatedContent.email}}',
      emailRequired: '{{system.version.data.gatedContent.emailRequired}}',
      position: '{{system.version.data.gatedContent.position}}',
      positionRequired: '{{system.version.data.gatedContent.positionRequired}}'
    } ]);
    system.storydocSenderInfo = JSON.stringify(this.senderDataService.personalInfoData$.value);
    let canonicalUrl = "{{prospectLink}}";
    if(this.isMarketingContentOrg) {
      canonicalUrl = url;
      if(story.settings.canonical === 'home') canonicalUrl = canonicalUrl.replace('home', '');
      system.placeholders.ogImage = pageSettings.og.image;
    }
    let doc = this.handlebarsService.renderHTML({
      url: canonicalUrl,
      system: system,
      settings: pageSettings,
      head: data.head,
      body: data.body,
      dependencies: data.dependencies + data.javascript
    });

    if(system.version) { // if rendering a version - add all 2nd compilation
      // MUST be synced with backend!
      const docTemplate = this.handlebarsService.compile(doc);
      doc = docTemplate({
        prospectLink: url,
        system
      })
    }

    // if(system.production) {
      doc = doc.replace(/<img src=/ig, '<img data-src=').replace(/<source src=/ig, '<source data-src=');
    // }
    return doc;
  }

  combinePageComponents(pageSettings, pageComponents, options = { ignoreInvisible: false }) {
    const ret: any = {};
    if(!pageComponents) return ret;
    // only if combining multiple page components: remove the invisible one's
    if(options.ignoreInvisible) pageComponents = pageComponents.filter(pc => !(pc.design && pc.design.invisible));
    ret.body = pageComponents.map(c => {
      return `
      <!-- Start PageComponents ${c.componentId} -->
      ${c.renderedHTML}
      <!-- end PageComponents ${c.componentId} -->
      `;
    }).join('\n');
    let uniquePageComponentIds = [];
    let uniquePageComponents = pageComponents.filter(c => {
      if(uniquePageComponentIds.includes(c.componentId)) {
        return false;
      } else {
        uniquePageComponentIds.push(c.componentId);
        return true;
      }
    });
    // dependencies should be only once per component (usually css and js files)
    ret.dependencies = uniquePageComponents.map(c => {
      if(!c.renderedDependenciesHTML) return
      return `
      <!-- Start PageComponents ${c.componentId} Dependencies -->
      ${c.renderedDependenciesHTML}
      <!-- end PageComponents ${c.componentId} Dependencies -->
      `;
    }).join('\n')
    ret.head = pageComponents.map(c => {
      return `
      <!-- Start PageComponents ${c.componentId} CSS -->
      <style type="text/css">
      ${c.renderedCss}
      </style>
      <!-- end PageComponents ${c.componentId} CSS -->
      `;
    }).join('\n');
    if(pageComponents[0].design && pageComponents[0].design.useImage && pageComponents[0].design.backgroundImage) {
      const imagePreload = `<link rel="preload" as="image" href="${this.updateToCloudinary(pageComponents[0].design.backgroundImage)}?tx=f_auto">\n\n`;
      ret.head = imagePreload + ret.head;
    }
    ret.javascript = pageComponents.map(c => {
      return `
      <!-- Start PageComponents ${c.componentId} javascript -->
      <script type="text/javascript">
        ${c.renderedJavascript}
      </script>
      <!-- end PageComponents ${c.componentId} javascript -->
      `;
    }).join('\n')
    return ret;
  }

}
