import { Component, Input, OnInit, OnDestroy } from "@angular/core";
import { NbDialogRef } from "@nebular/theme";
import { SubscriptionFacade } from "../subscription.facade";
import { Subscription, interval, Subject } from "rxjs";
import { Router } from "@angular/router";
import { environment } from "../../../../../environments/environment";
import { HelpCenterService } from "../../../help-center/help.service";
import { AccountsService } from "../../../../services/accounts.service";
import { switchMap, take, takeUntil } from "rxjs/operators";

@Component({
  selector: "ngx-purchase-subscription",
  templateUrl: "./purchase-subscription-dialog.component.html",
  styleUrls: ["./purchase-subscription-dialog.component.scss"],
})
export class PurchaseSubscriptionDialogComponent implements OnInit, OnDestroy {
  constructor(
    protected ref: NbDialogRef<PurchaseSubscriptionDialogComponent>,
    private subscriptionFacade: SubscriptionFacade,
    private router: Router,
    private helpCenterService: HelpCenterService,
    private accountsService: AccountsService
  ) {}

  @Input() title: string = '';
  @Input() subtitle: string = '';
  @Input() period: string = 'Annually';
  @Input() plan: string = 'Pro';
  @Input() source: string = '';

  showSummary: boolean = true;

  includes = {
    Starter: [
      "Export to PDF",
      "AI-generated text & images",
      "Free library of professionally designed templates",
      "Deck analytics & alerts",
      "On-slide AI assistant",
      "Up to 3 live templates",
      "10 live links per template"
    ],
    Pro: [
      "Unlimited slides and live templates",
      "Unlock Pro slides, features, and templates",
      "Custom branding. Storydoc badge removed",
      "Extended text & image AI tokens",
      "Personalization with dynamic variables",
      "Digital document approval",
      "Unlimited content integrations",
    ]
  }
  planInclude: string[] = [];
  addAdditionalSeats: boolean = false;
  isAnnualPayment: boolean = true;
  paddleInstance = null;
  userEmail: string = "";
  proPlanPrice: number;
  totalPrice: number;
  proPlanMonthlyPrice: number;
  promotionType: string = "";
  earlyBirdsPrice: number;
  isLoading$ = this.subscriptionFacade.isLoadingInlinePaddle$;
  subscriptionCallback: Subscription;
  totalUsersCount: number = 0;
  isEarlyBirds: boolean = false;
  showCounter: boolean = false;
  orgCreatedAt: Date = null;
  earlyBirdsPercentageDiscount: number;
  earlyBirdsPeriodInMinutes: number;
  days: number = 0;
  hours: number = 0;
  minutes: number = 0;
  seconds: number = 0;
  private countdownEndDate: Date;
  private countdownSubscription: Subscription;
  private unsubscribe$ = new Subject<void>();

  showAnnualPlanOnly: boolean = false;

  ngOnInit() {
    this.planInclude = this.includes[this.plan];
    this.showAnnualPlanOnly = this.accountsService.showAnnualPlanOnly;
    this.subscriptionFacade.checkoutDetails$.next(null);
    const testingGroup = this.accountsService.isAGroup ? 'testingGroupA' : 'testingGroupB';
    this.setEarlyBirdsPromotionValues(testingGroup);
    this.setTitleIfUnset();
    if (this.period === 'Monthly') this.isAnnualPayment = false;
    this.accountsService.getOrgInfo$().pipe(
      take(1),
      switchMap((orgInfo: any) => {
        this.orgCreatedAt = new Date(orgInfo.createdAt);
        this.setIsEarlyBirds(this.orgCreatedAt);
        return this.accountsService.getIsEarlyBirds$();
      }),
    ).subscribe(isEarly => {
      this.isEarlyBirds = isEarly;
      if (this.isEarlyBirds) {
        this.startCountdown();
      }
      this.handlePeriodPaymentValue();
      this.initiatePaddle();
      this.closeInlinePaddleCheckout();
      this.setPromotionType()
      if (this.promotionType) {
        this.subscriptionFacade.proDialogAnalytics(this.source, this.plan, this.promotionType)
      } else {
        this.subscriptionFacade.proDialogAnalytics(this.source, this.plan)
      }
    });
    this.subscriptionFacade.track('view payments popup', this.getEventProperties);
  }

  setTitleIfUnset() {
    if (!this.title) {
      this.title = (this.source === 'plans page' || this.source === 'click subscribe now header')
        ? 'Get full access to <br> all the <strong>Pro features</strong>'
        : 'Unlock this feature <br> by going <strong>Pro</strong>';
    }
  }

  setEarlyBirdsPromotionValues(group: string) {
    const groupSettings = environment.earlyBirds[group];
    if (groupSettings) {
      this.earlyBirdsPercentageDiscount = groupSettings.percentageDiscount;
      this.earlyBirdsPeriodInMinutes = groupSettings.periodInMinutes;
    }
  }

  setPromotionType() {
    if (this.isEarlyBirds) {
      this.promotionType = 'early birds';
    }
  }

  startCountdown() {
    this.countdownEndDate = new Date(this.orgCreatedAt);
    this.countdownEndDate.setMinutes(this.orgCreatedAt.getMinutes() + this.earlyBirdsPeriodInMinutes);

    this.countdownSubscription = interval(1000).pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(() => {
      const now = new Date().getTime();
      const distance = this.countdownEndDate.getTime() - now;

      this.days = Math.floor(distance / (1000 * 60 * 60 * 24));
      this.hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      this.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      this.seconds = Math.floor((distance % (1000 * 60)) / 1000);

      if (!this.showCounter) {
        this.showCounter = true;
      }

      if (distance < 0) {
        this.days = 0;
        this.hours = 0;
        this.minutes = 0;
        this.seconds = 0;
        this.isEarlyBirds = false;
        this.setIsEarlyBirds(this.orgCreatedAt);
        if (this.countdownSubscription) {
          this.countdownSubscription.unsubscribe();
        }
      }
    });
  }

  setIsEarlyBirds(orgCreatedAt: Date) {
    const now = new Date();
    const timeDiff = now.getTime() - orgCreatedAt.getTime();
    const minutesDiff = timeDiff / (1000 * 60);
    const isEarlyBirds = minutesDiff <= this.earlyBirdsPeriodInMinutes;
    this.accountsService.setIsEarlyBirds(isEarlyBirds);
  }

  padNumber(num: number): string {
    return num.toString().padStart(2, '0');
  }

  updateSummaryAndPaddle(updatedSeats: number) {
    this.calculatePrices(updatedSeats);

    const seats = {
      additionalSeats: 0,
      freeSeats: 0,
      quantity: updatedSeats
    };

    this.totalUsersCount = updatedSeats + 1;

    this.subscriptionFacade.initiatePaddle(seats, this.getPaymentPeriod(), this.plan, this.source);
  }

  calculatePrices(seatCount: number) {
    const seats = seatCount + 1;
    const planPrice = this.subscriptionFacade.getSelectedPlan(this.getPaymentPeriod(), this.plan).price;

    this.totalPrice = planPrice * seats;

    if (this.isEarlyBirds && this.isAnnualPayment) {
      this.totalPrice = this.proPlanMonthlyPrice * 12 * seats;
      this.earlyBirdsPrice = this.totalPrice - (this.totalPrice * this.earlyBirdsPercentageDiscount / 100);
    }
  }

  setPaymentTime(period: 'Annually'|'Monthly') {
    if ((period === 'Annually' && this.isAnnualPayment)
      || (period === 'Monthly' && !this.isAnnualPayment)) return;
    this.isAnnualPayment = period === 'Annually';
    if (!this.isAnnualPayment && this.addAdditionalSeats)
      this.addAdditionalSeats = false;
    this.handlePeriodPaymentValue();
    this.initiatePaddle();

    this.subscriptionFacade.track('click payments popup change plan length', this.getEventProperties);
  }

  handlePeriodPaymentValue() {
    const seats = this.calculatedSeatsHandler();
    this.totalUsersCount = seats.quantity + seats.freeSeats + 1;
    const period = this.getPaymentPeriod();
    const plan = this.subscriptionFacade.getSelectedPlan(period, this.plan);
    this.proPlanMonthlyPrice = this.subscriptionFacade.getSelectedPlan("Monthly", this.plan).price;
    this.proPlanPrice = this.isAnnualPayment ? plan.price / 12 : plan.price;
    this.totalPrice = plan.price * (seats.quantity + 1);
    if (this.isEarlyBirds && this.isAnnualPayment) {
      this.totalPrice = this.proPlanMonthlyPrice * 12 * (seats.quantity + 1);
      this.earlyBirdsPrice = this.totalPrice - (this.totalPrice * this.earlyBirdsPercentageDiscount / 100);
      this.proPlanPrice = this.proPlanMonthlyPrice - (this.proPlanMonthlyPrice * this.earlyBirdsPercentageDiscount / 100);
    }
    if (this.addAdditionalSeats) {
      const totalSeats = seats.quantity + 1;
      this.proPlanMonthlyPrice = plan.price / 12;
      this.totalPrice = plan.price * totalSeats;
      this.proPlanPrice = this.isAnnualPayment ? (this.totalPrice / (totalSeats + 2) / 12) : plan.price;
    }
  }

  initiatePaddle() {
    this.subscriptionFacade.checkoutDetails$.next(null);
    this.showSummary = true;
    const period = this.getPaymentPeriod();
    const seats = this.calculatedSeatsHandler();
    this.subscriptionFacade.initiatePaddle(seats, period, this.plan, this.source);
  }

  getTotalPrice(): number {
    return this.totalPrice
  }

  getPaymentPeriod(): string {
    return this.isAnnualPayment ? "Annually" : "Monthly";
  }

  close() {
    if (this.subscriptionCallback) this.subscriptionCallback.unsubscribe();
    this.ref.close();
  }

  calculateSeats(additionalSeats: number) {
    let discountSeats = 2;
    let freeSeats = 2;
    let remainingSeats = additionalSeats;
    if (remainingSeats > (discountSeats + freeSeats)) {
      return {
        additionalSeats: remainingSeats - (discountSeats + freeSeats),
        freeSeats: 2,
        quantity: 2 + (remainingSeats - (discountSeats + freeSeats))
      }
    } else if (remainingSeats <= (discountSeats + freeSeats)) {
      return {
        additionalSeats: 0,
        freeSeats: 2,
        quantity: 2
      }
    }
  }

  calculatedSeatsHandler() {
    const orgSeats = this.subscriptionFacade.getOrgSeats();
    let seats = {
      additionalSeats: 0,
      freeSeats: 0,
      quantity: orgSeats
    }
    if(this.addAdditionalSeats && this.isAnnualPayment) seats = this.calculateSeats(orgSeats)

    return seats;
  }

  closeInlinePaddleCheckout() {
    this.subscriptionCallback = this.subscriptionFacade.isSuccessPayment$.subscribe(res => {
      if (!res) return this.subscriptionFacade.closePaymentDialog();
      this.close();
      this.subscriptionFacade.openCheckoutPaymentDialog({title: `Welcome to Storydoc ${this.plan}!`});
      this.subscriptionFacade.deleteCookie("sd-paddle-coupon", "/", "storydoc.com");
    })
  }

  openCheckout() {
    this.showSummary = false;
  }

  navigatePlansPage() {
    window.open('/pages/profile/plans', '_blank');
  }

  navigateToTeams() {
    this.subscriptionFacade.getOrgInfo$().subscribe(info => {
      if (!info) return;
      this.close();
      const isExpired = this.subscriptionFacade.isExpiredTrial(info);
      this.subscriptionFacade.closeMediaDialog();
      if (isExpired) return this.onContactUs();
      this.router.navigateByUrl('/pages/profile/users');
    })
  }

  onContactUs() {
    const data = {
      topic: 'Account',
      subject: '',
      messagePlaceholder: ''
    }
    this.helpCenterService.setFormFieldsData(data)
    this.router.navigateByUrl('/pages/help');
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.subscriptionFacade.checkoutDetails$.next(null);
  }

  get getEventProperties() {
    return this.subscriptionFacade.getEventProperties(this.period, this.plan);
  }

  get displayedDiscount(): number {
    return Math.round(this.earlyBirdsPercentageDiscount);
  }

  protected readonly Math = Math
}
