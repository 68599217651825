import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, from, BehaviorSubject} from 'rxjs';
import {filter, map, shareReplay, switchMap, take, tap} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {EditorV4Service} from './editorv4.service';
import { ReactDataService } from './reactData.service';
import * as moment from 'moment';

interface PlanProps {
  freeSeats?: number,
  additionalSeats?: number,
  proTriggerTimestamp?: string,
  teamTriggerTimestamp?: string,
  integrationsTriggerTimestamp?: string,
  forceAdditionalSeats?: boolean
}

declare global {
  interface Window {
    clarity: any;
  }
}

@Injectable({
  providedIn: 'root'
})
export class AccountsService {

  constructor(
    private http: HttpClient,
    private editorV4Service: EditorV4Service,
    private reactDataService: ReactDataService,
  ) {
    this.proPlanIds = environment.paddle.plans.filter(plan => {
      if (plan.title === 'Pro') return plan.id;
    }).map(plan => plan.id)
  }

  orgInfo;
  accountCustomization$ = new BehaviorSubject<any>(null);
  proPlanIds = [];
  isEditorV4User = false;
  orgInfo$: BehaviorSubject<Record<string, any>> = new BehaviorSubject<Record<string, any>>(undefined);
  private accountCache$: Observable<any>;
  private isEarlyBirdsSubject$ = new BehaviorSubject<boolean>(false);

  get(): Observable<any> { // admin only
    return this.http.get(environment.backend + '/accounts');
  }

  search(search: string): Observable<any> { // admin only
    return this.http.post(environment.backend + '/accounts/search', {search});
  }

  info(force = false, update = true): Observable<any> {
    if(this.orgInfo && !force) return from([this.orgInfo]);
    return this.http.get(environment.backend + '/accounts/info')
      .pipe(
        tap(orgInfo => {
          this.orgInfo$.next(orgInfo);
          if(update) {
            this.orgInfo = orgInfo;
            this.editorV4Service.orgInfo$.next(orgInfo);
            this.reactDataService.orgInfo$.next(orgInfo);
          }
          if(this.orgInfo && this.orgInfo.parameters && this.orgInfo.parameters.isEditorV4) this.isEditorV4User = true;
        })
      );
  }

  getOrgInfo$() {
    return this.orgInfo$.pipe(filter(info => !!info));
  }

  updateOrgInfo(orgInfo: Record<string, any>) {
    this.orgInfo = orgInfo;
    this.reactDataService.orgInfo$.next(orgInfo);
    this.orgInfo$.next(orgInfo);
  }

  setOrgId(id: string) {
    this.orgInfo = {
      ...this.orgInfo,
      _id: id
    }; 
  }

  getCancellationDiscountEligibility(): Observable<any> {
    return this.http.get(environment.backend + '/accounts/verify-cancellation-discount-eligibility')
  }

  applyCancellationDiscount() {
    return this.http.get(environment.backend + '/accounts/apply-cancellation-discount')
  }

  setupAccountOnExternalPayment({ checkoutId, userId, userEmail}:{ checkoutId: string, userId: string, userEmail: string }): Observable<any> {
    return this.http.post(environment.backend + '/payments/setup-payment', {
      checkoutId,
      userId,
      userEmail
    });
  }

  firstVersionCreation(): Observable<any> {
    return this.http.post(environment.backend + '/accounts/firstVersionCreation', {})
  }

  firstVersionShared(): Observable<any> {
    return this.http.post(environment.backend + '/accounts/firstVersionShared', {})
  }

  postTrialVisitDate(): Observable<any> {
    return this.http.post(environment.backend + '/accounts/postTrialVisit', {})
  }


  paymentPendingStatus(status = true): Observable<any> {
    return this.http.post(environment.backend + '/accounts/payment-pending', {status})
  }

  firstLogin(): Observable<any> {
    return this.http.post(environment.backend + '/accounts/firstLogin', {});
  }

  updateWizardPayload(payload: Record<string, unknown>): Observable<any> {
    return this.http.post(environment.backend + '/accounts/update-wizard-payload', { payload });
  }

  updateDsId(dsId, website, industry, usecase, additionalData): Observable<any> {
    return this.http.post(environment.backend + '/accounts/updateDsId', {
      dsId,
      website,
      industry,
      usecase,
      additionalData
    });
  }

  updateOrgInfoProps(website: string = '', industry: string = '', usecase: string = '', prompt: string = ''): Observable<any> {
    return this.http.post(environment.backend + '/accounts/updateOrgInfoProps', {website, industry, usecase, prompt});
  }

  updateDsIdOnly(dsId): Observable<any> {
    return this.http.post(environment.backend + '/accounts/updateDsId', {dsId});
  }

  updateAdminCustomization(data) {
    return this.http.post(environment.backend + '/accounts/admin-customization', data);
  }

  createUser(user): Observable<any> {
    // user
    // {
    //   orgId,
    //   email,
    //   firstName,
    //   lastName,
    //   editor,
    //   selfServ
    // }
    return this.http.post(environment.backend + '/users/createUser', user);
  }

  createAccount(parameters): Observable<any> {
    if (!this.accountCache$) {
      this.accountCache$ = this.http.post(environment.backend + '/accounts', { parameters }).pipe(shareReplay(1))
    }
    return this.accountCache$;
  }

  deleteAccount(): Observable<any> {
    return this.http.delete(environment.backend + '/accounts');
  }

  sendVerificationEmail() {
    return this.http.post(environment.backend + '/accounts/sendVerificationEmail', {
      redirectUrl: window.location.href + '?verified'
    });
  }

  getOrgVersionsCount(): Observable<{count: number}> {
    return this.http.get<{count: number}>(`${environment.backend}/versions/count`);
  }

  updatePlan(planId): Observable<any> {
    return this.http.post(environment.backend + `/accounts/updatePlan`, {planId});
  }

  handlePlanProperties(data: PlanProps): Observable<any> {
    return this.http.post(environment.backend + `/accounts/plan-properties`, data);
  }

  cancelPlan(): Observable<any> {
    return this.http.post(environment.backend + `/accounts/cancelPlan`, {});
  }

  extendTrialPlan(): Observable<any> {
    return this.http.post(environment.backend + `/accounts/extendTrialPlan`, {});
  }

  allowFreeSubscription({id, period = 1}): Observable<any> {
    return this.http.post(environment.backend + `/accounts/allow-free-subscription`, {id, period});
  }

  addSeats(seats, description = undefined): Observable<any> {
    return this.http.post(environment.backend + `/accounts/addSeats`, {seats, description});
  }

  buySeats(seats): Observable<any> {
    return this.http.post(environment.backend + `/accounts/buySeats`, {seats});
  }

  customization(customization): Observable<any> {
    return this.http.post(environment.backend + '/accounts/customization', customization)
  }

  getInvoices(): Observable<any> {
    return this.http.get(environment.backend + `/accounts/getInvoices`);
  }

  postMixpanelEvent(event: string, payload: Record<string, unknown>, segment: boolean = false): Observable<unknown> {
    return this.http.post(environment.backend + `/accounts/mixpanel-event`, { event, payload, segment });
  }

  updateTimestamp(key: string) {
    return this.http.post(environment.backend + `/accounts/timestamp`, { key });
  }

  updateAccountCustomization(customization) {
    this.accountCustomization$.next(customization);
  }

  isEditorV4Org(): Observable<any> {
    if (typeof this.isEditorV4User !== 'undefined') return from([ this.isEditorV4User ]);
    return this.getOrgInfo$().pipe(
      take(1),
      switchMap(orgInfo => {
        return from([orgInfo && orgInfo.parameters && orgInfo.parameters.isEditorV4]);
      })
    );
  }

  setEditorV4User(status): Observable<any> {
    const parameters = {
      isEditorV4: status
    }
    return this.http.post(environment.backend + '/accounts/updateOrgParameters', {parameters});
  }

  updateOrgParameters(parameters): Observable<any> {
    return this.http.post(environment.backend + '/accounts/updateOrgParameters', {parameters});
  }

  addCustomFonts(fonts: {title: string, url: string, weight: number}[]): Observable<any> {
    return this.http.post(environment.backend + '/accounts/custom-fonts', { fonts });
  }

  addPredefinedFonts(fonts: {title: string, value: string}[]): Observable<any> {
    return this.http.post(environment.backend + '/accounts/predefined-fonts', { fonts });
  }

  setAccessControl({ access, duration, reason = "" }): Observable<any> {
    return this.http.post(environment.backend + '/accounts/access-control', { access, duration, reason });
  }

  setMobileOnboardingStarted(payload: {'doc_type': string, 'subtype': string, 'preview_gif': string, 'story_id': string}): Observable<any> {
    return this.http.post(environment.backend + '/accounts/mobile-onboarding-started', { payload });
  }
  
  setMobileOnboardingCompleted(): Observable<any> {
    return this.http.post(environment.backend + '/accounts/mobile-onboarding-finished', {});
  }

  setIsEarlyBirds(isEarlyBirds: boolean) {
    this.isEarlyBirdsSubject$.next(isEarlyBirds);
  }

  getIsEarlyBirds$(): Observable<boolean> {
    return this.isEarlyBirdsSubject$.asObservable();
  }

  getStaticIsEarlyBirds() {
    return this.isEarlyBirdsSubject$.getValue();
  }

  getIsEarlyBirdsSubscription$(): Observable<boolean> {
    return this.getOrgInfo$().pipe(
      map((orgInfo: any) => {
        const earlyBirdCoupons = Object.values(environment.earlyBirds).map(({ coupon }) => coupon);
        const oneYearLater = new Date(orgInfo.firstPaidAt);
        oneYearLater.setFullYear(oneYearLater.getFullYear() + 1);

        const now = new Date();
        const isWithinOneYear = now < oneYearLater;

        return earlyBirdCoupons.includes(orgInfo.paymentCoupon) && isWithinOneYear;
      })
    );
  }

  getIsCancellationDiscountClaimed$(): Observable<boolean> {
    return this.getOrgInfo$().pipe(
      map((orgInfo: any) => {
        if (!orgInfo.cancellationDiscountApplyingDate) return false;
        const today = new Date();
        const cancellationDate = new Date(orgInfo.cancellationDiscountApplyingDate);
        return today <= cancellationDate;
      })
    );
  }

  getCancellationDiscountAmount$(): Observable<boolean> {
    return this.getOrgInfo$().pipe(
      map((orgInfo: any) => {
        if (!orgInfo.cancellationDiscountApplyingDate || !orgInfo.cancellationDiscountAmount) return null;
        return orgInfo.cancellationDiscountAmount;
      })
    );
  }

  isSubscriptionExpired(time = null) {
    if(!time) return false;
    const now = moment(new Date());
    const end = moment(time);
    const duration = moment.duration(end.diff(now));
    const days = duration.asDays();
    return days <= 0;
  }

  getPlanById(id: string | undefined) {
    if (!id) return null;
    return environment.paddle.plans.find(plan => plan.id === id);
  }

  get isB2B() {
    if(!this.orgInfo) return false;
    return this.orgInfo.isSelfServ !== true || (!this.isSubscribed && this.isPaid && this.orgInfo.appsumoPlanId !== 'storydoc_tier1');
  }
  get isPaid() {
    if(!this.orgInfo) return false;
    return this.orgInfo.plan === 'paid' || this.orgInfo.plan === undefined;
  }
  get isSubscribed() {
    if(!this.orgInfo) return false;
    return this.orgInfo.subscriptionId != null
  }

  get paddlePlan() {
    if (!this.orgInfo) return null;
    if (this.isAppSumo) {
      return environment.appSumo.plans.find(plan => plan.id === this.orgInfo.appsumoPlanId);
    }
    return environment.paddle.plans.find(plan => plan.id === this.orgInfo.subscriptionPlanId);
  }

  get periodText() {
    const plan: any = this.paddlePlan || {};
    return plan.period || 'Monthly';
  }

  get planUsersLimit() {
    if (!this.orgInfo) return 1;
    const { planLimit, additionalSeats, freeSeats } = this.getAccountSeats;
    return Number(planLimit) + Number(additionalSeats) + Number(freeSeats);
  }

  get getAccountSeats() {
    const currentPlan: any = this.paddlePlan;
    let planLimit          = currentPlan && currentPlan.seats ? currentPlan.seats : 1;
    let additionalSeats = this.orgInfo.additionalSeats || 0;
    let freeSeats = this.orgInfo.planProperties ? (this.orgInfo.planProperties.freeSeats || 0) : 0;
    const totalPaidSeats = Number(planLimit) + Number(additionalSeats);
    return { planLimit, additionalSeats, freeSeats, totalPaidSeats }
  }

  get seatPrice() {
    const plan: any = this.paddlePlan || {};
    return plan.seatPrice;
  }

  get mrr() {
    const plan = this.paddlePlan;
    if(!plan) return undefined
    const mrr = plan.period === 'Annually' ? plan.price / 12 : plan.price;
    return mrr * this.getAccountSeats.totalPaidSeats;
  }

  get isAppSumo() {
    return !!this.orgInfo.appsumoUuid;
  }

  get isStarter() {
    return !!(this.paddlePlan && this.paddlePlan.title === 'Starter') && this.isCreatedAfterJuly19;
  }

  get isStarterOrTrial() {
    return (this.isStarter || !this.isPaid)
  }

  get isTeam() {
    if(!this.orgInfo) return false;
    return this.isPaid && !this.orgInfo.isSelfServ;
  }

  get isCanceled() {
    return !!this.orgInfo && !!this.orgInfo.cancelAt;
  }

  get isPro() {
    return !!(this.paddlePlan && this.paddlePlan.title === 'Pro') || (!!(this.paddlePlan && this.paddlePlan.title === 'Starter') && !this.isCreatedAfterJuly19)
  }

  get liveStoriesLimit () {
    if (this.isStarterOrTrial) return 3;
    if (this.isPro) return 1000;
  }

  get bulkSendLimit () {
    if (this.isStarterOrTrial) return 10;
    if (this.isPro || this.isTeam) return 500;
  }

  get isCreatedAfterJuly19() {
    return new Date(this.orgInfo.createdAt).getTime() > new Date('2023-07-19T08:00:00.000+00:00').getTime()
  }

  testingGroupA = 'A - testing group';
  testingGroupB = 'B - testing group';

  get testingGroup() {
    if(!this.orgInfo) return this.testingGroupA;
    return ((this.orgInfo.testingNumber || 2) % 2 === 0) ? this.testingGroupA : this.testingGroupB;
  }

  get testingNumber() {
    if (!this.orgInfo || !this.orgInfo.testingNumber) return 0;
    return Number(this.orgInfo.testingNumber);
  }

  get isAGroup() {
    return this.testingGroup === this.testingGroupA;
  }

  get isMobileFlow(): boolean {
    if (!this.orgInfo) return false;
    return !!this.orgInfo.mobileOnboardingStartedAt && !this.orgInfo.mobileOnboardingCompletedAt;
  }

  get showAnnualPlanOnly() {
    // if (!this.orgInfo) return true;
    // if(!this.orgInfo.firstPaidAt) return true;
    // const firstPaidAt = new Date(this.orgInfo.firstPaidAt);
    // return firstPaidAt.getTime() > new Date('2025-02-11T12:10:00.000Z').getTime();
    return false;
  }
}
