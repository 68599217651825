import { Component, Input } from "@angular/core";
import { SubscriptionDialogFacade } from "../../../pages/profile/subscription/subscription-dialog.facade";

@Component({
  selector: "ngx-go-pro-button",
  templateUrl: "go-pro-button.component.html",
  styleUrls: ["go-pro-button.component.scss"],
})
export class GoProButtonComponent {
  constructor(private subscriptionDialogFacade: SubscriptionDialogFacade) {}

  @Input() style: Record<string, string>
  @Input() title: string = '';
  @Input() source: string = '';
  @Input() disabled: boolean = false;
  
  upgradeToPro() {
    if (this.disabled) return;
    this.subscriptionDialogFacade.openPurchaseDialog({ title: this.title, source: this.source})
  }
}
