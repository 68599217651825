import { Injectable } from "@angular/core";
import { NbDialogService } from "@nebular/theme";
import { UpgradeToTeamDialogComponent } from "./upgrade-to-team-dialog/upgrade-to-team-dialog.component";
import { PurchaseSubscriptionDialogComponent } from "./purchase-subscription-dialog/purchase-subscription-dialog.component";
import { AuthService } from "../../../auth.service";
import { BlockProUpgradeDialogComponent } from "./block-pro-upgrade-dialog/block-pro-upgrade-dialog.component";
import { UpgradeStarterToProDialogComponent } from "./upgrade-starter-to-pro-dialog/upgrade-starter-to-pro-dialog.component";
import { AccountsService } from "../../../services/accounts.service";
import { switchMap } from "rxjs/operators";
import { from } from "rxjs";
import { SuccessUpdatePlanDialog } from "./success-update-plan-dialog/success-update-plan-dialog.component";
import { AnalyticsService } from "../../../services/analytics.service";

interface ProContext {
    source: string;
    title?: string;
    subtitle?: string;
    period?: string;
    plan?: string;
}


@Injectable({
    providedIn: "root",
})

export class SubscriptionDialogFacade {
  isAdministrator: boolean = false;

    constructor(
        private dialogService: NbDialogService,
        private auth: AuthService,
        private accountsService: AccountsService,
        private analyticsService: AnalyticsService
    ) {
    }

    openPurchaseDialog(context: ProContext) {
        this.auth.isAdministrator$().subscribe(res => {
          this.isAdministrator = res;

          if (!this.isAdministrator) return this.openBlockPurchaseDialog();
          if (this.accountsService.isStarter && !this.accountsService.isCanceled) return this.openUpgradeStarterToProDialog();
          const plan = context.plan || 'Pro';
          this.analyticsService.gtmAnalytics(`open ${plan.toLowerCase()} dialog`);
          this.dialogService.open(PurchaseSubscriptionDialogComponent, {
            closeOnBackdropClick: false,
            closeOnEsc: false,
            context: {
              ...context,
              plan
            }
          });
        });
    }

    upgradeToTeamDialog(source: string = '', title: string | null = null) {
        const context: {title?: string, source?: string} = {};
        if (title) context.title = title;
        if (source) context.source = source;
        this.dialogService.open(UpgradeToTeamDialogComponent, {
            closeOnBackdropClick: false,
            closeOnEsc: false,
            context
        });
    }

    openUpgradeStarterToProDialog() {
        this.dialogService.open(UpgradeStarterToProDialogComponent, {
            closeOnBackdropClick: false,
            closeOnEsc: false,
        }).onClose.pipe(switchMap(res => {
            if (!res) return from([ null ]);
            return this.dialogService.open(SuccessUpdatePlanDialog, {
                closeOnBackdropClick: false,
                closeOnEsc: false,
                context: {}
            }).onClose
        })).subscribe()
    }


    openBlockPurchaseDialog() {
        this.dialogService.open(BlockProUpgradeDialogComponent, { context: {} })
    }

}
