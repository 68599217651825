import { Component, Input } from "@angular/core";
import { SubscriptionDialogFacade } from "../../../pages/profile/subscription/subscription-dialog.facade";

@Component({
  selector: "ngx-pro-plan-label",
  templateUrl: "pro-plan-label.component.html",
  styleUrls: ["pro-plan-label.component.scss"],
})
export class ProPlanLabelComponent {
  constructor(private subscriptionDialogFacade: SubscriptionDialogFacade) { }
  
  @Input() source: string = ''
  @Input() icon: boolean = false

  upgradeToPro() {
    this.subscriptionDialogFacade.openPurchaseDialog({source: this.source})
  }
}
